/* Contenedor principal */
.dashboard-container {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
    /* Centra el contenedor */
    background: #f8f9fa;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;

    /* Prevención de elementos ocultos */
    overflow-y: auto;
    /* Permite scroll vertical si es necesario */
    position: relative;
    /* Asegura que los elementos hijos se posicionen dentro */
    min-height: 100vh;
    /* Asegura que ocupe al menos la altura de la pantalla */
}

/* Título */
.dashboard-title {
    text-align: center;
    font-size: 2rem;
    /* Aumenta el tamaño del título */
    color: #333;
    margin: 20px 0;
    /* Asegura espacio arriba y abajo */
    position: sticky;
    /* Mantiene el título visible si es necesario */
    top: 0;
    /* Se pega a la parte superior al hacer scroll */
    background: #f8f9fa;
    /* Fondo para evitar que se mezcle con otros elementos */
    z-index: 10;
    /* Asegura que esté por encima de otros elementos */
    padding: 10px;
    /* Espaciado interno */
}

/* Responsividad */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
        max-width: 100%;
    }

    .dashboard-title {
        font-size: 1.5rem;
        /* Ajusta el tamaño del título para pantallas pequeñas */
    }
}


/* Tarjetas */
.card {
    background: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: 1px solid #e9ecef;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Títulos de las secciones */
.card h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #495057;
    border-bottom: 2px solid #e9ecef;
    padding-bottom: 5px;
}

/* Inputs y botones */
input[type="text"],
input[type="number"],
input[type="date"],
button {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ced4da;
    outline: none;
}

input[type="text"]:focus,
input[type="number"]:focus,
input[type="date"]:focus,
button:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

button {
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button:hover {
    background-color: #0056b3;
}

/* Lista de visitas */
.visita-item {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #e9ecef;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: background-color 0.3s ease;
}

.visita-item:hover {
    background-color: #f1f1f1;
}

.visita-item span {
    font-size: 0.9rem;
    font-weight: 500;
}

/* Indicadores de estado */
.visita-item.green {
    border-left: 5px solid #28a745;
}

.visita-item.red {
    border-left: 5px solid #dc3545;
}

/* Responsividad */
@media (max-width: 768px) {
    .dashboard-container {
        padding: 10px;
    }

    .dashboard-title {
        font-size: 1.5rem;
    }

    input[type="text"],
    input[type="number"],
    input[type="date"],
    button {
        font-size: 0.9rem;
    }

    .visita-item span {
        font-size: 0.8rem;
    }
}