body {
    font-family: 'Roboto', sans-serif;
    background-color: #f5f7fa;
    margin: 0;
    padding: 0;
}

.registro-visita-form-modern {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.form-container {
    background: #fff;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
}

h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1rem;
}

.alert {
    padding: 10px;
    border-radius: 5px;
    margin-bottom: 1rem;
    font-size: 0.9rem;
}

.alert.success {
    background: #e8f5e9;
    color: #2e7d32;
}

.alert.error {
    background: #ffebee;
    color: #c62828;
}

.form-group {
    margin-bottom: 1rem;
}

label {
    display: block;
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #555;
}

input,
textarea,
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

input:focus,
textarea:focus,
select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.signature-canvas-modern {
    border: 2px dashed #ccc;
    border-radius: 5px;
    width: 100%;
    height: 150px;
    margin-bottom: 1rem;
}

.signature-buttons {
    display: flex;
    justify-content: space-between;
}

.btn-primary,
.btn-secondary {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.btn-primary {
    background-color: #007bff;
    color: #fff;
}

.btn-primary:hover {
    background-color: #0056b3;
}

.btn-secondary {
    background-color: #f5f5f5;
    color: #333;
}

.btn-secondary:hover {
    background-color: #e0e0e0;
}

.btn-submit {
    background-color: #28a745;
    color: white;
    width: 100%;
    padding: 12px;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.btn-submit:hover {
    background-color: #218838;
}

.form-scroll-container {
    max-height: 70vh;
    /* Altura fija con scroll */
    overflow-y: auto;
    padding: 1rem;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: #fff;

    /* Por defecto, el ancho ocupa el 90% del viewport */
    width: 100%;
    margin: 0 auto;
    /* Centra el formulario */
}

/* Estilos específicos para pantallas grandes */
@media (min-width: 768px) {
    .form-scroll-container {
        width: 100%;
        /* Más ancho en dispositivos medianos */
    }
}

@media (min-width: 1024px) {
    .form-scroll-container {
        width: 80%;
        /* Más ancho en dispositivos grandes */
    }
}

@media (min-width: 1440px) {
    .form-scroll-container {
        width: 100%;
        /* Ajusta el ancho en pantallas muy grandes */
    }
}