/* Vervisitas.css */
.visitas-container {
    background: linear-gradient(135deg, #222831, #393e46);
    min-height: 100vh;
    padding: 40px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #eeeeee;
    width: 100vw;
    overflow-x: hidden;
}

.status-buttons {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.status-buttons button {
    padding: 10px 20px;
    background-color: #64b5f6;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.status-buttons button:hover {
    background-color: #4e92f2;
}

.visitas-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
}

.visita-card {
    background-color: #ffffff;
    color: #333333;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
}

.visita-card h3 {
    margin-bottom: 10px;
}

/* Efecto de destellos para las visitas del día */
.visita-card.highlight-today {
    border-left: 6px solid #81c784;
    /* Verde claro para el borde */
    animation: glow 1.5s infinite alternate;
    /* Efecto de destello */
}

@keyframes glow {
    0% {
        box-shadow: 0 0 5px #81c784, 0 0 15px #81c784, 0 0 30px #81c784;
    }

    100% {
        box-shadow: 0 0 10px #81c784, 0 0 20px #81c784, 0 0 40px #81c784;
    }
}

.contact-icons a {
    margin-right: 10px;
    font-size: 20px;
    color: #4caf50;
}

.contact-icons a:hover {
    color: #388e3c;
}